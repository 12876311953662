<template>
  <!-- 公示公告 -->
  <div id="notice">
    <div class="pageCont container tl">
      <bread class="nav" :title="title" :subtitle="subtitle"></bread>
      <el-divider></el-divider>
      <!-- v-if="showData" -->
      <div v-loading="loading" element-loading-text="拼命加载中">
        <el-tabs v-model="activeName" @tab-click="toChangeJYLB">
          <el-tab-pane
            v-for="(item, index) in navFirstLists"
            :key="index"
            :label="item.name"
            :name="item.code.toString()"
          >
            <div class="navs">
              <ul class="navUl flex">
                <li
                  v-for="(item, index) in navSecondLists"
                  :key="index"
                  :class="{ active: index == isActiveClick }"
                  @click="toChangeAddress(index)"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
            <div class="notice-cont" v-if="showData" style="min-height:300px;">
              <ul class="contUl">
                <li
                  class="contLi"
                  v-for="(item, index) in lists"
                  :key="index"
                  @click="
                    lookDetail(item.id, item.zylb, item.jyfs, item.goodsid)
                  "
                >
                  <p class="news text-18 text-bold">
                    【{{ item.ggbh }}】{{ item.title }}
                  </p>
                  <p class="date text-14">
                    <img
                      style="margin:-2px 5px 0 0;"
                      src="~@/assets/images/time.png"
                      alt
                    />
                    {{ item.createtime | filterTime }}
                  </p>
                </li>
              </ul>
            </div>
            <div
              v-else
              class="notice-cont margin-top"
              style="min-height:300px;"
            >
              暂无数据！
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- <div v-else style="min-height:300px;">暂无数据！</div> -->
      <div class="pagination">
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentChange"
          :page-size="10"
          :current-page.sync="tableParams.pageCurrent"
          layout="prev, pager, next, jumper"
          :total="tableParams.total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import bread from "@/components/breadcrumb.vue";

export default {
  data() {
    return {
      loading: false,
      title: "公示公告",
      subtitle: "",
      activeName: "0", // 一级高亮
      isActiveClick: 0, // 二级高亮
      navFirstLists: [], // 一级菜单
      navSecondLists: [], // 二级菜单
      tableParams: {
        pageSize: 10,
        pageCurrent: 1,
        total: null,
      },
      lists: [],
      showData: true,
      navList: [
        {
          name: "全部",
        },
        {
          name: "中央",
        },
        {
          name: "省级",
        },
        {
          name: "市级",
        },
      ],
    };
  },
  created() {
    this.initZDB("zylb"); // 公告交易类别(字典表：jylb
    this.initZDB("jyfs"); // 公告交易方式(字典表：jyfs
    this.getData(0, 0);
  },
  methods: {
    // 查询字典表--新闻动态标题
    initZDB(val) {
      let param = {
        dcode: val,
      };
      // 使用带IP的 字典表 -- 后期需要修改
      this.apis.api.getSerchZDB2(param).then((res) => {
        if (res.data.code == "01") {
          let datas = res.data.dat;
          // tab一级
          if (val == "zylb") {
            for (var i = 0; i < datas.length; i++) {
              if (datas[i].name == "集体建设用地使用权") {
                datas[i].name = "集体经营性建设用地交易";
              }
              if (datas[i].name == "采矿权") {
                datas.splice(i, 1);
              }

              if (datas[i].name == "探矿权") {
                datas.splice(i, 1);
              }
            }
            for (var k = 0; k < datas.length; k++) {
              if (datas[k].name == "二级市场") {
                datas[k].name = "土地二级市场交易";
              }
            }

            datas.push({
              name: "矿业权交易",
              code: "3,4",
            });

            // datas.unshift({
            //   name: "国有建设土地交易",
            //   code: "1",
            // });

            this.navFirstLists = datas;
            this.navFirstLists.unshift({
              name: "全部",
              code: 0,
            });
          } else if (val == "jyfs") {
            for (var z = 0; z < datas.length; z++) {
              // if (datas[z].name == "协议") {
              // 	datas[z].name = '协议公告'
              // }
              // if (datas[z].name == "挂牌") {
              // 	datas[z].name = '挂牌公告'
              // }
              // if (datas[z].name == "拍卖") {
              // 	datas[z].name = '拍卖公告'
              // }
              if (datas[z].code == "-1") {
                datas[z].name = "补充公告";
              }
              if (datas[z].code == "1") {
                datas[z].name = "挂牌公告";
              }
              if (datas[z].code == "2") {
                datas[z].name = "拍卖公告";
              }
              if (datas[z].code == "3") {
                datas[z].name = "协议公告";
              }
            }

            //  tab二级
            this.navSecondLists = datas;
            this.navSecondLists.unshift({
              name: "全部",
              code: 0,
						});
						this.navSecondLists.push({
              name: "补充公告",
              code: '-1',
            });
          }
        }
      });
    },

    // 获取数据
    getData(val, val2) {
      let param = {
        ggjyfs: val,
        ggjylb: val2,
        pageNum: this.tableParams.pageCurrent,
        pageSize: this.tableParams.pageSize,
      };
      this.loading = true;
      this.apis.api.getNoticeList(param).then((res) => {
        if (res.data.code == "01") {
          let datas = res.data.dat;
          this.loading = false;
          this.lists = datas;
          this.tableParams.total = res.data.count;
          if (datas == "" || datas == null || datas == undefined) {
            this.showData = false;
          } else {
            this.showData = true;
          }
        }
      });
    },

    // 切换一级菜单
    toChangeJYLB(tab) {
      this.tableParams.pageCurrent = 1;
      this.tableParams.pageSize = 10;

      this.getData(tab.name, 0);
      this.isActiveClick = 0;
    },

    // 切换二级菜单
    toChangeAddress(index) {
      this.tableParams.pageCurrent = 1;
      this.tableParams.pageSize = 10;

      this.isActiveClick = index;
      this.getData(this.activeName, index=='4'?'-1':index);
    },

    // 跳转到详情页面
    lookDetail(id, zylb, jyfs, goodsid) {
      this.$router.push({
        name: "noticeDetail",
        query: {
          id: id,
          goodsid: goodsid,
          child: "公示公告",
          path: "/notice",
          zylb: zylb,
          jyfs: jyfs,
          gglb: this.isActiveClick,
        },
      });
    },

    // 切换分页
    sizeChange(val) {
      var _this = this;
      _this.tableParams.pageSize = val;
      //方法  传参
      _this.getData(this.activeName, this.isActiveClick);
      //方法  传参
    },
    // 分页
    currentChange(val) {
      var _this = this;
      _this.tableParams.pageCurrent = val;
      _this.getData(this.activeName, this.isActiveClick);
    },
  },
  components: {
    bread,
  },
};
</script>
<style lang="scss" scoped>
#notice {
  .pageCont {
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;

    .navs {
      width: 460px;
      height: 30px;

      .navUl {
        margin-top: 3px;

        li {
          margin-right: 10px;
          width: 80px;
          height: 30px;
          line-height: 30px;
          box-sizing: border-box;
          text-align: center;
          color: #333333;
          background-color: #fff;
          border-radius: 22px;
          border: 1px solid #ccc;
          cursor: pointer;

          &:hover {
            color: #fff;
            background-color: rgba(2, 167, 240, 1);
            border: none;
          }

          &:active {
            opacity: 0.8;
          }
        }

        .active {
          color: #fff;
          background-color: rgba(2, 167, 240, 1);
          border: none;
        }
      }
    }

    .notice-cont {
      // width: calc(100% - 180px);
      .contUl {
        .contLi {
          // display: flex;
          // justify-content: space-between;
          height: 85px;
          font-size: 16px;
          border-bottom: 1px solid #ccc;

          .news {
            padding: 20px 0 6px;
            box-sizing: border-box;
            width: 1000px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: #000;
            cursor: pointer;

            &:hover {
              color: #02a7f0;
            }
          }

          .date {
            color: #aaaaaa;
            // &:hover {
            //   color: #0e83c9;
            // }
          }
        }
      }
    }

    .pagination {
      padding-top: 20px;
      height: 55px;
    }
  }

  // 分割线
  /deep/ .el-divider--horizontal {
    margin: 0 0 10px;
  }

  /deep/ .el-tabs__item {
    margin: 5px 0;
    font-size: 16px;
  }
}
</style>
